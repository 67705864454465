<template>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <c-table
        ref="table"
        title="점검항목 목록"
        :columns="itemGrid.columns"
        gridHeight="520px"
        :data="data.itemList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :editable="editable && !disabled"
        rowKey="chmDangerJournalItemId"
      >
        <template slot="suffixTitle">
          <font class="text-negative" style="font-size:0.8em;font-weight:300;">
            O : 양호  X : 불량  △ : 요정비
          </font>
        </template>
      </c-table>
    </div>
</template>

<script>
export default {
  name: 'danger-journal-item',
  props: {
    data: {
      type: Object,
      default: () => ({
        itemList: [],
      }),
    },
    itemGrid: {
      height: 'auto',
      columns: [],
      data: [],
    },
  },
  data() {
    return {
      editable: true,
      searchUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    disabled() {
     return this.data.chmDangerCompleteFlag === 'Y' || this.data.approvalStatusCd === 'ASC0000001'
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
    },
  }
};
</script>
