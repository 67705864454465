var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-lg-12 col-md-12 col-sm-12" },
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "점검항목 목록",
            columns: _vm.itemGrid.columns,
            gridHeight: "520px",
            data: _vm.data.itemList,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            hideBottom: true,
            editable: _vm.editable && !_vm.disabled,
            rowKey: "chmDangerJournalItemId",
          },
        },
        [
          _c(
            "template",
            { slot: "suffixTitle" },
            [
              _c(
                "font",
                {
                  staticClass: "text-negative",
                  staticStyle: { "font-size": "0.8em", "font-weight": "300" },
                },
                [_vm._v(" O : 양호 X : 불량 △ : 요정비 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }